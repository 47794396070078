<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Blocked phone numbers')" :description="$t('pages.block_phone_numbers.page_desc')">
        <block-tools>
          <li>
            <nk-button type="primary" v-on:click="openAddEditRecordModal()">
              <nio-icon icon="ni-plus"></nio-icon> {{$t('Add New')}}
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <card no-padding>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{ $t('Bulk Action') }}</option>
                        <option value="delete">{{ $t('Delete') }}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{ $t('Apply') }}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="bpn_page_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: records.displayOrder === 'asc'}"><a href="javascript:;" v-on:click="[records.displayOrder='asc', getPhoneNumbers()]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: records.displayOrder === 'desc'}"><a href="javascript:;" v-on:click="[records.displayOrder='desc', getPhoneNumbers()]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search')"
                  :is-active="searchBarActive"
                  @do-search="searchRecords"
                  :search-term="filters.search"
                  @close-search="[searchBarActive=false, searchRecords('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="dataIsLoading">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="adv_select_all" v-model="records.selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="sm">
                      <span class="sub-text">ID</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">{{ $t('Phone') }}</span>
                    </table-col>
                    <table-col>{{ $t('Blocked by') }}</table-col>
                    <table-col break-point="md">{{ $t('Reason')}}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="(num, i) in records.records" :key="'bn_tr'+i">
                    <table-col has-check-box>
                      <b-check-box :id="'bn_ckb'+i" v-model="num.selected" :key="'bn_ckb'+num.id"></b-check-box>
                    </table-col>
                    <table-col break-point="sm">{{ num.id }}</table-col>
                    <table-col>{{ num.phone_no}}</table-col>
                    <table-col>{{ num.blocked_by}}
                      <span class="sub-text">{{num.created_at}}</span>
                    </table-col>
                    <table-col break-point="md">{{ num.reason}}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-trash-empty" :text="$t('Delete')" v-on:click="deleteRecord([num])"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>
              </div>
              <div class="card-inner" v-if="records.totalRecords > 10">
                <pagination
                    v-model="records.page"
                    :records="records.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal
        :is-open="addEditRecord.modalOpen"
        @didDismiss="addEditRecord.modalOpen=false"
        :css-class="isDesktop ? 'modal-web' : ''">
      <i-modal :title="$t(addEditRecord.data.id ? 'Edit record' : 'Add record')" @modal-close="addEditRecord.modalOpen=false">
        <form-group>
          <label class="overline-title" for="n_phone_no">{{$t('Phone number')}}</label>
          <b-input type="number" id="n_phone_no" v-model="addEditRecord.data.phone_no"/>
        </form-group>
        <form-group>
          <label class="overline-title" for="n_phone_reason">{{$t('Reason')}}</label>
          <textarea class="form-control" id="n_phone_reason" v-model="addEditRecord.data.reason"></textarea>
        </form-group>
        <template v-slot:footer class="p-5">
          <nk-button type="primary" class="mr-2" v-on:click="saveRecord">{{$t(addEditRecord.data.id ? 'Update' : 'Add')}}</nk-button>
          <a href="javascript:;" class="link link-light" v-on:click="addEditRecord.modalOpen=false">{{$t('Cancel')}}</a>
        </template>
      </i-modal>
    </ion-modal>
  </page-template>
</template>

<script>
import {loadingController, toastController, IonModal, IonSpinner, isPlatform, onIonViewWillEnter, alertController} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import axios from "@/libs/axios"
import IModal from "@core/components/ion-modal/IModal";
import {useI18n} from "vue-i18n";

import "bootstrap"

import {BlockContent, BlockHead, BlockTools, FormGroup, MainBlock} from "@core/layouts";
import {BCheckBox, BInput} from "@core/components/bp-form";
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";
import {BtnToolbar, BtnToolbarToggleWrap} from "@core/components/btn-toolbar";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import DropDown from "@core/components/dropdown/DropDown";
import Pagination from "v-pagination-3";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    BlockTools,
    CardSearch,
    DropDown,
    ListItem,
    DropDownList,
    BCheckBox,
    TableCol,
    TableRow,
    SpecialTable,
    BtnToolbarToggleWrap,
    BtnToolbar,
    CardTools,
    CardHeader,
    BInput,
    FormGroup,
    BlockHead,
    Card,
    BlockContent,
    MainBlock,
    Pagination,
    IonModal,
    IonSpinner,
    IModal,
  },
  setup() {

    const{t} = useI18n()
    const isDesktop   = isPlatform('desktop')
    let dataIsLoading = ref(false)
    let records   = reactive({
      displayOrder: 'desc',
      page: 1,
      totalRecords: 0,
      records: [],
      selectAll: false,
    });
    let filters = reactive({
      search: '',
      countryCode: ''
    })
    let searchBarActive = ref(false)

    let addEditRecord = reactive({
      data:{
        id: 0,
        phone_no: '',
        reason: '',
      },
      modalOpen: false,
    })

    let cancelToken = false
    const getPhoneNumbers = async () => {
      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      dataIsLoading.value = true
      axios.post('/api/admin/settings/blocked_phone_numbers/get',
          {page: records.page, order: records.displayOrder, search: filters.search, country_code: filters.countryCode},
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(!resp.data.success){
              toastController.create({header: t('Error'), message: resp.data.message, position: 'top', color: 'danger', duration: 4000}).then((r)=> r.present())
              return false
            }
            for(let x in resp.data.data.records){
              resp.data.data.records[x].selected = false
            }
            records.records = resp.data.data.records
            if(records.page === 1){
              records.totalRecords = resp.data.data.total_records
            }
          })
          .catch( err => {
            if(err.toString() === 'Cancel'){
              return
            }
            const msg = err.response ? err.response.status+' '+err.response.statusText : t('errors.general_error')
            toastController.create({header: t('Error'), message: msg, position: 'top', color: 'danger', duration: 4000}).then((r)=> r.present())
          })
          .then(()=>{
            cancelToken = false
            dataIsLoading.value = false
          })
    }

    onIonViewWillEnter(()=> getPhoneNumbers())

    const openAddEditRecordModal = ( record ) => {
      addEditRecord.data.id =  record ? record.id : 0
      addEditRecord.data.phone_no = record?.phone_no || ''
      addEditRecord.data.reason = record ? record.reason : ''
      addEditRecord.modalOpen = true
    }
    const saveRecord = async () => {

      const loading = await loadingController.create({message: t('Processing...')})
      const toast   = await toastController.create({position: "top", duration: 3000})
      await loading.present()


      axios.put('/api/admin/settings/blocked_phone_numbers', addEditRecord.data)
          .then( resp => {
            toast.message = resp.data.success ? t('Thank you! action completed') : resp.data.message
            toast.color   = resp.data.success ? "primary" : "danger"
            if( resp.data.success ){

              addEditRecord.modalOpen = false
              if(addEditRecord.data.id > 0){
                for(let x in records.records){
                  if(records.records[x].id === addEditRecord.data.id){
                    records.records[x] = resp.data.data
                    break;
                  }
                }
              }
              else{
                records.records.unshift(resp.data.data)
              }
            }
            toast.present()
          })
          .catch(error => {
            toast.message = t('Error')+': '+(error.response ? error.response.status+' '+error.response.statusText : t('errors.general_error'))
            toast.color = "danger"
            toast.present()
          })
          .then( () => loading.dismiss())
    }
    const deleteRecord = async ( data ) => {

      const loading = await loadingController.create({message: t('Please wait...')})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let ids = []
      for(let x in data){
        ids.push( data[x].id)
      }

      let iAlert = await alertController.create({
        cssClass: isDesktop ? 'alert-web' : '',
        header: `${t( ids.length > 1  ? 'Delete records' : 'Delete record')}`,
        message: t('action_confirmation',{action: 'delete', count: ids.length, type: t(ids.length > 1 ? 'records': 'record')}),
        buttons: [
          {
            text: t('Cancel'),
            cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
          },
          {
            cssClass: isDesktop ? 'alert-btn-danger' : '',
            text: t('Confirm'), handler: () => {
              loading.present()
              axios.delete('/api/admin/settings/blocked_phone_numbers', {data: {ids: JSON.stringify(ids)}})
                  .then( resp => {
                    toast.message = resp.data.success ? t('Thank you! action completed') : resp.data.message
                    toast.color   = resp.data.success ? "primary" : "danger"
                    if(resp.data.success){
                      // Remove the deleted entries from the advisors array
                      for(let x in ids){
                        for(let y in records.records){
                          if(records.records[y].id === ids[x]){
                            records.records.splice(y,1)
                            break;
                          }
                        }
                      }
                      // All deleted, no entry left in the advisor array? and previous pages exists
                      if( !records.records.length && records.page > 1 ){
                        // Deduct from total records value
                        records.totalRecords -= ids.length
                        // Goto previous page
                        records.page--
                        getPhoneNumbers()
                      }
                    }
                    toast.present()
                  })
                  .catch(error => {
                    toast.message = t('Error')+': '+(error.response ? error.response.status+' '+error.response.statusText : t('errors.general_error'))
                    toast.color = "danger"
                    toast.present()
                  })
                  .then( () => loading.dismiss())
            }
          },
        ]
      })
      await iAlert.present()
    }


    watch(()=> records.selectAll, (n) => {
      for(let x in records.records){
        records.records[x].selected = n
      }
    })

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selected = []
      for(let x in records.records){
        if( records.records[x].selected){
          selected.push( records.records[x])
        }
      }
      if( selected.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'delete' ){
        await deleteRecord(selected)
      }
    }

    const searchRecords = (search) => {
      filters.search = search
      getPhoneNumbers()
    }

    return {
      addEditRecord,
      bulkAction,
      bulkSelectedAction,
      deleteRecord,
      records,
      getPhoneNumbers,
      saveRecord,
      dataIsLoading,
      filters,
      isDesktop,
      openAddEditRecordModal,
      searchBarActive,
      searchRecords,
    }
  }
});

</script>
